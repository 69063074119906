import React from "react";

import styled from "styled-components";
import { bp, hexToRGBA } from "@styles/theme";

import { Carousel } from "@components/Carousel";
import { Flex } from "./Flex";
import { ProductCard } from "@components/Card/ProductCard";
import { Section } from "@components/Section";
import { AnyProduct } from "@api/graphql/types";
import { useLoctool } from "@bigfish/react-loctool";

interface RecommendedProductsProps {
    products: AnyProduct[];
    count?: number;
    bgColor?: "beige" | "white";
    onProductClick?: () => void;
}

export const RecommendedProducts: React.FC<RecommendedProductsProps> = ({ products, count, bgColor = "beige", onProductClick }) => {
    const Intl = useLoctool();
    return (
        <StyledSection
            $count={count}
            title={Intl.formatMessage({ id: "pages.home.addedToCartModal.relatedProducts" })}
            background={bgColor}
            className="_ce_measure_row"
        >
            <div className="_ce_measure_column">
                <StyledCarousel
                    className="“_ce_measure_widget"
                    data-ce-measure-widget="masok-ezt-vasaroltak-meg"
                    options={{
                        cellAlign: "center",
                        contain: true,
                        wrapAround: false,
                        prevNextButtons: false,
                        imagesLoaded: true,
                        groupCells: true,
                        pageDots: true,
                        draggable: true,
                    }}
                >
                    {count
                        ? products.slice(0, count).map(product => {
                              return (
                                  <CarouselCell key={product.id}>
                                      <StyledProductCard
                                          product={product}
                                          className="carousel_cell_content"
                                          boughtByOthers
                                          hideType
                                          onClick={() => (onProductClick ? onProductClick() : null)}
                                      />
                                  </CarouselCell>
                              );
                          })
                        : products.map(product => {
                              return (
                                  <CarouselCell key={product.id}>
                                      <StyledProductCard
                                          onClick={() => (onProductClick ? onProductClick() : null)}
                                          product={product}
                                          className="carousel_cell_content"
                                          boughtByOthers
                                          hideType
                                      />
                                  </CarouselCell>
                              );
                          })}
                </StyledCarousel>
                <StyledProductCardGrid>
                    {count
                        ? products.slice(0, count).map(product => {
                              return (
                                  <CarouselCell key={product.id}>
                                      <StyledProductCard
                                          product={product}
                                          className="carousel_cell_content"
                                          boughtByOthers
                                          hideType
                                          onClick={() => (onProductClick ? onProductClick() : null)}
                                      />
                                  </CarouselCell>
                              );
                          })
                        : products.map(product => {
                              return (
                                  <CarouselCell key={product.id}>
                                      <StyledProductCard
                                          product={product}
                                          className="carousel_cell_content"
                                          boughtByOthers
                                          hideType
                                          onClick={() => (onProductClick ? onProductClick() : null)}
                                      />
                                  </CarouselCell>
                              );
                          })}
                </StyledProductCardGrid>
            </div>
        </StyledSection>
    );
};

const StyledProductCard = styled(ProductCard)`
    width: 100%;
`;

const StyledProductCardGrid = styled(Flex.Container).attrs({
    $display: "flex",
    $justifyContent: "center",
    $flexWrap: "wrap",
})`
    display: none;

    @media screen and (min-width: 992px) {
        display: flex;
        width: 100%;
        padding-bottom: 30px;
    }

    ${bp.large} {
        width: calc(100% + 32px);
        margin-left: -16px;
    }
`;

const CarouselCell = styled(Flex.Container)`
    width: 100%;
    max-width: 240px;
    min-height: 100%;
    padding: 0 10px;

    &:nth-of-type(1),
    &:nth-last-of-type(1) {
        max-width: 246px;
    }

    &:nth-of-type(1) {
        padding-left: 16px;
    }

    &:nth-last-of-type(1) {
        padding-right: 16px;
    }

    @media screen and (min-width: 768px) {
        margin: 10px 0;
    }

    @media screen and (min-width: 992px) {
        width: 25%;
        max-width: unset;
        min-height: 100%;
        padding: 0 10px 10px;
        margin: 0;

        &:nth-of-type(1),
        &:nth-last-of-type(1) {
            max-width: unset;
        }
    }

    ${bp.xlarge} {
        padding: 0 20px 10px;
    }
`;

const StyledCarousel = styled(Carousel)`
    padding-bottom: 60px;

    &:focus {
        outline: 0;
    }

    @media screen and (min-width: 992px) {
        display: none;
    }

    .flickity-page-dots {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        bottom: 0;

        .dot {
            width: 13px;
            height: 13px;
            margin: 0 5px;
            background-color: ${p => hexToRGBA(p.theme.color.grayD, 0.2)};
            border-radius: 50%;
            cursor: pointer;
            transition: background-color 200ms, width 200ms, height 200ms;

            &.is-selected {
                width: 18px;
                height: 18px;
                background-color: ${p => hexToRGBA(p.theme.color.grayD, 0.5)};
            }
        }
    }
`;

const StyledSection = styled(Section)<{ $count?: number }>`
    width: 100%;

    h2 {
        padding-right: 15px;
        padding-left: 15px;
    }

    > div {
        padding-right: 0;
        padding-left: 0;

        ${bp.large} {
            padding-right: 50px;
            padding-left: 50px;
        }
    }

    ${p =>
        p.$count === 3 &&
        `
        ${bp.medium} {
            > div {
                padding-right: 0;
                padding-left: 0;
            }

            ${StyledCarousel} {
                display: none;
            }

            ${StyledProductCardGrid} {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                width: 100%;
                padding: 0 10px;
            }
        }

        ${bp.large} {
            ${StyledProductCardGrid} {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                width: calc(100% + 60px);
                padding: 0 10px;
                margin-left: -30px;
            }
        }

        ${bp.xlarge} {
            ${CarouselCell} {
                width: 33.3334%;
            }
        }
    `}
`;
