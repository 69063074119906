import React, { PropsWithChildren } from "react";

import styled from "styled-components";

import { Button } from "@components/Button";
import { Container } from "@components/Container";
import { Flex } from "@components/Flex";

interface GiftProps {
    icon?: boolean;
    className?: string;
}

export const Gift: React.FC<PropsWithChildren<GiftProps>> = ({ children, icon, className }) => {
    return <StyledGift className={className}>{children}</StyledGift>;
};

const StyledGift = styled(Flex.Container).attrs({
    $justifyContent: "center",
    $alignItems: "center",
})`
    max-width: 100%;
    padding: 4px;
    background-color: ${props => props.theme.color.beigeXL};
    text-align: center;
`;

export const GiftFullWidth: React.FC<PropsWithChildren<GiftProps>> = ({ children }) => {
    return (
        <StyledGiftFullWidth>
            <Container>{children}</Container>
        </StyledGiftFullWidth>
    );
};

const StyledGiftFullWidth = styled.div`
    padding: 8px 0;
    background-color: ${props => props.theme.color.yellowL};
    text-align: center;
`;
