import React, { PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import Link from "next/link";
import { bp } from "@styles/theme";
import { Container } from "@components/Container";
import { Typography } from "@components/Typography";
import { underlineCenter } from "@components/Layout";

interface SectionProps {
    title: string;
    background: "beige" | "white";
    linkTitle?: string;
    linkHref?: string;
    className?: string;
}

export const Section: React.FC<PropsWithChildren<SectionProps>> = ({ title, background, linkTitle, linkHref, children, className }) => {
    return (
        <StyledSection className={className} $background={background}>
            <Container>
                <SectionHeader>
                    {linkHref && linkTitle && (
                        <Link href={linkHref}>
                            <SectionLink>{linkTitle} »</SectionLink>
                        </Link>
                    )}
                    <SectionTitle>{title}</SectionTitle>
                </SectionHeader>
                {children}
            </Container>
        </StyledSection>
    );
};

const StyledSection = styled.section<{ $background: "beige" | "white" }>`
    ${props =>
        props.$background === "beige" &&
        css`
            background-color: ${props => props.theme.color.beige};
        `};

    ${props =>
        props.$background === "white" &&
        css`
            background-color: ${props => props.theme.color.white};
        `};
`;

const SectionHeader = styled.div`
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;

    ${bp.medium} {
        padding-top: 40px;
    }

    ${bp.large} {
        padding-top: 55px;
        padding-bottom: 40px;
    }
`;

const SectionLink = styled(Typography.UpTitle).attrs({
    as: "a",
})`
    display: inline-block;
    margin-bottom: 15px;
    font-size: 25px;
    line-height: 28px;

    ${bp.large} {
        margin-bottom: 10px;
    }

    ${bp.large} {
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 5px;
    }
`;

export const SectionTitle = styled(Typography.H2)`
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    ${underlineCenter}

    ${bp.large} {
        font-size: 40px;
        line-height: 60px;
    }
`;
