import React, { createContext } from "react";
import Flickity from "react-flickity-component";
import "flickity/css/flickity.css";

interface CarouselContextProps {
    handlePrevious?: () => void;
    handleNext?: () => void;
}

export const CarouselContext = createContext<CarouselContextProps | undefined>(undefined);

interface CarouselProps {
    className?: string;
    elementType?: string;
    options?: { [key: string]: any };
    disableImagesLoaded?: boolean;
    reloadOnUpdate?: boolean;
    static?: boolean;
    controls?: React.ReactNode;
    measureWidget?: string;
    resizeCells?: boolean;
}

const resizeCells = (flickity: any[]) => {
    flickity.forEach((slide: { parent: { cells: any[]; viewport: { style: { height: string } } } }) => {
        const cellHeights = slide.parent.cells.map(
            (cell: { element: { querySelector: (arg0: string) => { (): any; new (): any; offsetHeight: any } } }) =>
                cell.element.querySelector(".carousel_cell_content").offsetHeight,
        );
        const largestCellHeight = Math.max(...cellHeights);

        slide.parent.viewport.style.height = `${largestCellHeight}px`;

        slide.parent.cells.forEach((cell: any) => {
            cell.element.style.height = `${largestCellHeight}px`;
        });
    });
};

export class Carousel extends React.Component<CarouselProps> {
    children: React.ReactNode;
    flkty: any;

    componentDidMount = () => {
        if (this.props.resizeCells) {
            this.flkty.on("ready", () => resizeCells(this.flkty.slides));
            this.flkty.on("resize", () => resizeCells(this.flkty.slides));
        }
    };

    handleNext = () => {
        this.flkty.next();
    };

    handlePrevious = () => {
        this.flkty.previous();
    };

    render() {
        return (
            <div style={{ position: "relative" }} className="_ce_measure_row">
                <CarouselContext.Provider value={{ handlePrevious: this.handlePrevious, handleNext: this.handleNext }}>
                    {this.props.controls}
                </CarouselContext.Provider>
                <Flickity
                    flickityRef={c => (this.flkty = c)}
                    className={this.props.className}
                    elementType={this.props.elementType || "div"}
                    options={this.props.options}
                    disableImagesLoaded={this.props.disableImagesLoaded || false}
                    reloadOnUpdate={this.props.reloadOnUpdate || false}
                    static={this.props.static || false}
                >
                    {this.props.children}
                </Flickity>
            </div>
        );
    }
}

interface CarouselButtonProps {
    className?: string;
    label: string;
    icon: React.ReactNode;
    id?: string;
}

export class CarouselButtonPrevious extends React.Component<CarouselButtonProps> {
    static contextType = CarouselContext;

    onClick = () => {
        this.context.handlePrevious();
    };

    render() {
        return (
            <button className={this.props.className} aria-label={this.props.label} onClick={this.onClick}>
                {this.props.icon}
            </button>
        );
    }
}

export class CarouselButtonNext extends React.Component<CarouselButtonProps> {
    static contextType = CarouselContext;

    onClick = () => {
        this.context.handleNext();
    };

    render() {
        return (
            <button className={this.props.className} aria-label={this.props.label} onClick={this.onClick} id={this.props.id}>
                {this.props.icon}
            </button>
        );
    }
}
