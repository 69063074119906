import React, { useEffect, useMemo, useState } from "react";

import Link from "next/link";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { AnyProduct } from "@api/graphql/types";
import { NormalProduct, productIsDigital } from "@api/graphql/typeHelpers";
import { useLoctool } from "@bigfish/react-loctool";
import { Button, CloseButton } from "@components/Button";
import { DigitalImageFrame } from "@components/DigitalImageFrame";
import { Flex } from "@components/Flex";
import { Gift } from "@components/Gift";
import Modal from "@components/Modal";
import { RecommendedProducts } from "@components/RecommendedProducts";
import { SVGCheckmark } from "@components/SVGCollection";
import { ApplicationState } from "@redux/reducers";
import { bp, hexToRGBA } from "@styles/theme";
import { CURRENCY_FORMAT_OPTIONS } from "@utils/Constants";
import { ImageUtils } from "@utils/ImageUtils";
import { Path } from "@utils/Path";

import { groupByProducts } from "./CartMenu";
import { HtmlUtils } from "@utils/HtmlUtils";

interface Props {
    mounted: boolean;
    product: NormalProduct;
    onClose: () => void;
}

const CartProductAddedModal: React.FC<Props> = ({ mounted, onClose, product }) => {
    const Intl = useLoctool();
    const [productAdded, setProductAdded] = useState(product);
    const { items } = useSelector((state: ApplicationState) => ({ items: state.cart.normalCart.items }));
    const [recommendedProducts, setRecommendedProducts] = useState<AnyProduct[]>([]);
    const originalRecommendedProucts = useSelector((state: ApplicationState) => state.cart);

    const cartItems = items.filter(item => item.product.id !== productAdded.id);
    const additionalItems = cartItems.filter(cartItem => {
        return recommendedProducts.some(product => productAdded.id === cartItem.product.id);
    });
    const groupedAdditionalItems = useMemo(() => groupByProducts(additionalItems), [additionalItems]);

    useEffect(() => {
        setRecommendedProducts(originalRecommendedProucts.recommendedProducts);
    }, []);

    return (
        <StyledModal
            titleText="CheckoutAuthModal"
            mounted={mounted}
            onModalClose={onClose}
            className="_ce_measure_widget"
            data-ce-measure-widget="popup-cartProduct-added-modal"
        >
            <ModalContent>
                <ModalHeader>
                    <ModalAlert>
                        <SVGCheckmark />
                        <ModalAlertTitle>{Intl.formatMessage({ id: "pages.home.addedToCartModal.success" })}</ModalAlertTitle>
                    </ModalAlert>
                    <ModalCloseButton onClick={() => onClose()} />
                </ModalHeader>
                <Link href={Path.cart}>
                    <ModalGoToCartMobile>{Intl.formatMessage({ id: "components.menu.cartMenu.cartContents" })}</ModalGoToCartMobile>
                </Link>
                <ModalProduct>
                    <ModalCartItem>
                        {productIsDigital(productAdded) ? (
                            <ModalDigitalProductImage
                                image={ImageUtils.productImageWithFallback(productAdded, { width: 100 })}
                                alternate={productAdded.name}
                                className="productAddedImage"
                            />
                        ) : (
                            <ModalProductImage {...ImageUtils.productImageWithFallback(productAdded, { width: 100 })} alt={productAdded.name} />
                        )}
                        <ModalProductDetails>
                            <ModalProductName>
                                <div dangerouslySetInnerHTML={{ __html: HtmlUtils.sanitize(productAdded?.name) }} />
                            </ModalProductName>
                            {productIsDigital(productAdded) && (
                                <ModalProductIsDigital>{Intl.formatMessage({ id: "pages.home.addedToCartModal.digitalSubscription" })}</ModalProductIsDigital>
                            )}
                            {productAdded?.text_gift && <Gift icon={true}>{productAdded.text_gift}</Gift>}
                        </ModalProductDetails>
                        <ModalProductPrice>{Intl.formatNumber(productAdded?.price?.unit_price ?? 0, CURRENCY_FORMAT_OPTIONS)}</ModalProductPrice>
                    </ModalCartItem>
                    {groupedAdditionalItems.map(item => (
                        <ModalCartItem key={item.product.id}>
                            <ModalProductImage {...ImageUtils.productImageWithFallback(item.product as NormalProduct, { width: 148 })} />
                            <ModalProductDetails>
                                <ModalProductName>{item.product?.name}</ModalProductName>
                                {productIsDigital(item.product) && (
                                    <ModalProductIsDigital>
                                        {Intl.formatMessage({ id: "pages.home.addedToCartModal.digitalSubscription" })}
                                    </ModalProductIsDigital>
                                )}
                                {item.product?.text_gift && <Gift icon={true}>{item.product.text_gift}</Gift>}
                            </ModalProductDetails>
                            <ModalProductQuantity>{item.quantity} db</ModalProductQuantity>
                            <ModalProductPrice>
                                {Intl.formatNumber((item.product?.price?.unit_price ?? 0) * item.quantity, CURRENCY_FORMAT_OPTIONS)}
                            </ModalProductPrice>
                        </ModalCartItem>
                    ))}
                    <Flex.Container>
                        <Button.Secondary onClick={onClose}>{Intl.formatMessage({ id: "components.menu.cartMenu.continueShopping" })}</Button.Secondary>
                        <Link href={Path.cart}>
                            <ModalProductCartButton>{Intl.formatMessage({ id: "components.menu.cartMenu.cartContents" })}</ModalProductCartButton>
                        </Link>
                    </Flex.Container>
                </ModalProduct>
                {recommendedProducts.length > 0 && (
                    <StyledRecommendedProducts onProductClick={() => onClose()} products={recommendedProducts} count={3} bgColor="white" />
                )}
            </ModalContent>
        </StyledModal>
    );
};

export default CartProductAddedModal;

const StyledRecommendedProducts = styled(RecommendedProducts)`
    width: 100%;
`;

const StyledModal = styled(Modal)`
    overflow-y: auto;

    ${bp.medium} {
        width: calc(100% - 30px);
        margin: 35px 15px;
    }

    ${bp.large} {
        width: 960px;
        margin: 70px 0;
    }

    ${bp.xlarge} {
        width: 1110px;
        margin: 140px 0;
    }
`;

const ModalContent = styled(Flex.Container).attrs({
    $flexDirection: "column",
    $alignItems: "center",
})`
    min-height: 100vh;
    background-color: ${props => props.theme.color.white};

    ${bp.medium} {
        min-height: unset;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 20px;
    }

    ${bp.large} {
        padding-left: 60px;
        padding-right: 60px;
        padding-bottom: 50px;
    }
`;

const ModalHeader = styled(Flex.Container)`
    position: relative;
    width: 100%;
    padding: 20px 56px 20px 16px;

    ${bp.large} {
        padding: 20px 0;
        border-bottom: 1px dashed ${props => props.theme.color.line};
    }
`;

const ModalCloseButton = styled(CloseButton)`
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
`;

const ModalGoToCartMobile = styled(Button.Primary)`
    ${bp.large} {
        display: none;
    }
`;

const ModalProduct = styled.div`
    display: none;
    width: 100%;
    padding: 30px 0;
    border-top: 1px dashed ${props => props.theme.color.line};
    border-bottom: 1px dashed ${props => props.theme.color.line};

    ${bp.large} {
        display: block;
        padding-right: 40px;
        padding-left: 40px;
    }
`;

const ModalProductImage = styled.img`
    width: 100px;
`;

const ModalDigitalProductImage = styled(DigitalImageFrame)`
    width: 100%;
    max-width: 100px;
`;

const ModalProductDetails = styled.div`
    min-width: 350px;
    margin-left: 80px;
    margin-right: 80px;
`;

const ModalProductName = styled.h2`
    margin-bottom: 6px;
    font-size: 22px;
    line-height: 35px;
    font-weight: 700;
`;

const ModalProductIsDigital = styled.p`
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 24px;
`;

const ModalProductQuantity = styled.span`
    align-self: center;
    margin-left: 100px;
    font-size: 18px;
    line-height: 18px;
`;

const ModalProductPrice = styled.span`
    align-self: center;
    margin-left: auto;
    font-size: 35px;
    line-height: 50px;
    font-weight: 700;
`;

const ModalCartItem = styled(Flex.Container)`
    margin-bottom: 40px;

    .productAddedImage {
        padding-top: 14%;
    }
`;

const ModalProductCartButton = styled(Button.Primary)`
    margin-left: auto;
`;

const ModalAlert = styled(Flex.Container).attrs({
    $alignItems: "center",
})`
    padding: 8px 10px;
    background-color: ${props => hexToRGBA(props.theme.color.green, 0.2)};

    svg {
        min-width: 25px;
        margin-right: 16px;
    }

    ${bp.large} {
        width: fit-content;
        padding: 8px 70px;
        margin: 0 auto;
    }
`;

const ModalAlertTitle = styled.span`
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;

    ${bp.large} {
        font-size: 18px;
        line-height: 27px;
    }
`;
